<template>
  <div id="app">
    <h1>Quiz</h1>
  </div>
</template>

<script>
export default {
  name: 'App',
  components: {
  }
}
</script>

<style>
body {
    margin: 0;
    font-family: 'Lato', sans-serif;
}
h1, h2, h3, h4, h5, p, strong {
    margin: 0;
    padding: 0;
}
</style>
